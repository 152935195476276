import React from 'react';
import { Layout, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';

import { brandingConfig } from '../../config';

const { Header } = Layout;

const SurveyLink = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Header
      style={{
        margin: '0',
        padding: '0',
        height: 'auto',
        lineHeight: '20px',
        zIndex: 9998,
      }}
    >
      <Typography.Text
        style={{
          background: brandingConfig.colors.accent1,
          color: '#041e33',
          fontSize: isMobile ? 14 : 16,
          lineHeight: isMobile ? '24px' : '32px',
          width: '100%',
          display: 'block',
          textAlign: 'center',
          zIndex: 9999,
          fontWeight: 500,
          padding: '0 10px',
        }}
      >
        <a
          target="_blank"
          rel="noreferrer"
          href="https://form.typeform.com/to/EjlsnkXN"
          style={{
            color: '#041e33',
            textDecoration: 'none',
          }}
        >
          Feeling lucky? Take the
          {' '}
          <span
            style={{
              textDecoration: 'underline',
            }}
          >
            Seagull Survey
          </span>
          {' '}
          and maybe win a YETI cooler!
        </a>
      </Typography.Text>
    </Header>
  );
};

export default SurveyLink;
