import React from 'react';
import Helmet from 'react-helmet';
import { Button } from 'antd';
import { useMediaQuery } from 'react-responsive';

import { brandingConfig } from '../../config';
import { USERSNAP_API_KEY, USERSNAP_GLOBAL_API_KEY } from '../../constant/usersnap/constant';

const NavUsersnap = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const showUsersnap = () => {
    window.Usersnap.show(`${USERSNAP_API_KEY}`).then((widgetApi) => widgetApi.open());
  };

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
            window.onUsersnapCXLoad = function(api) {
              api.init();
              window.Usersnap = api;

              api.on('open', function(event) {
                  event.api.setValue('GIT_SHA', '${process.env.REACT_APP_GIT_SHA}');
              });
            }
            var script = document.createElement('script');
            script.defer = 1;
            script.src = 'https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad';
            document.getElementsByTagName('head')[0].appendChild(script);
          `}
        </script>
      </Helmet>
      <Button
        onClick={showUsersnap}
        style={{
          background: 'transparent',
          color: brandingConfig.colors.buttonText,
          border: 'none',
          padding: '0',
          fontSize: isMobile ? 19 : 15,
          // lineHeight: '20px',
          fontWeight: '500',
          zIndex: '9999',
          borderRadius: '0',
          textAlign: 'left',
          // height: '20px',
        }}
        className="open-usersnap-feedback"
        block
      >
        Provide Feedback
      </Button>
    </>
  );
};

export default NavUsersnap;
