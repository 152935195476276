import React, {
  memo, useState, useContext, useRef, useEffect,
} from 'react';
import {
  Button, Input, Layout, Menu,
} from 'antd';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { AuthState } from '@aws-amplify/ui-components';
import './styles.scss';
import ProfileMenu from './ProfileMenu';
import MobileMenu from './MobileMenu';
import Logo from './Logo';
import { CloseIcon, MenuIcon } from '../icons';
import SearchDrawer from '../search-drawer';
import { featureFlag } from '../../constant';
import UserContext from '../../contexts/UserContext';
import PreferencesMenu from './PreferencesMenu';
import NavUsersnap from './NavUsersnap';

const { Header } = Layout;

const TopBar = (props) => {
  const {
    logoVisible,
    getSelectedKey,
    setAuthDrawerOpen,
    handleSignOut,
  } = props;

  const [visible, setVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);

  const {
    authState,
    globalSearch,
    setGlobalSearch,
    setSearchActive,
  } = useContext(UserContext);

  const location = useLocation();
  const searchBtn = useRef(null);

  const onClick = () => setVisible(!visible);

  const onClose = () => setVisible(false);

  const onCloseSearch = () => setSearchVisible(false);

  const showSearch = () => setSearchVisible(!searchVisible);

  const onChange = (e) => setGlobalSearch(e.target.value);

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      searchBtn?.current.click();
    }
  };

  const onClickSignIn = () => {
    setAuthDrawerOpen(true);
  };

  const selectedKey = getSelectedKey();

  useEffect(() => {
    if (location.pathname !== '/search') {
      setGlobalSearch('');
    }
  }, [location]);

  return (
    <>
      <Header className={classnames('top-bar')}>
        <div className="mobile-menu">
          {visible ? <CloseIcon onClick={onClose} style={{ fontSize: 28, color: '#fff', width: 20 }} />
            : <Button style={{ width: 40 }} icon={<MenuIcon />} onClick={onClick} />}
        </div>
        <Logo visible={logoVisible} setAuthDrawerOpen={setAuthDrawerOpen} />
        <div className="top-bar-left">
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[selectedKey]}>
            <Menu.Item key="map">
              <Link to="/map" onClick={() => setAuthDrawerOpen(false)}>
                <span>Map</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="data-console">
              <Link to="/data-console" onClick={() => setAuthDrawerOpen(false)}>
                <span>Console</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="feedback">
              <NavUsersnap />
            </Menu.Item>
          </Menu>
        </div>

        <div className="top-bar-right">
          { !visible
            ? (
              <>
                <MediaQuery minWidth={767}>
                  <Input
                    className="searchbox"
                    placeholder="Search Seagull by Keyword"
                    onChange={onChange}
                    onKeyPress={onEnter}
                    size="small"
                    value={globalSearch}
                    suffix={(
                      <Link to={`/search?query=${globalSearch}`} ref={searchBtn} onClick={() => setSearchActive(true)}>
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<SearchOutlined />}
                        />
                      </Link>
                    )}
                  />
                </MediaQuery>
                <MediaQuery maxWidth={767}>
                  {searchVisible ? <CloseIcon onClick={onCloseSearch} style={{ fontSize: 28, color: '#fff' }} />
                    : <SearchOutlined style={{ fontSize: 20, color: '#fff' }} onClick={showSearch} /> }
                </MediaQuery>
              </>
            ) : '' }
          {featureFlag.userPreferences && authState !== AuthState.SignedIn && authState !== 'cognitoHostedUI' && (
            <MediaQuery minWidth={767}>
              <PreferencesMenu />
            </MediaQuery>
          )}
          {featureFlag.userProfile && (
            <MediaQuery minWidth={767}>
              <ProfileMenu isMobile={false} handleSignOut={handleSignOut} onClickSignIn={onClickSignIn} />
            </MediaQuery>
          )}
        </div>
      </Header>
      <MobileMenu visible={visible} onClose={onClose} handleSignOut={handleSignOut} onClickSignIn={onClickSignIn} />

      {!location.pathname?.includes('/landing')
        ? <SearchDrawer visible={searchVisible} onClose={onCloseSearch} /> : ''}
    </>
  );
};

TopBar.propTypes = {
  logoVisible: PropTypes.bool,
  getSelectedKey: PropTypes.func,
  setAuthDrawerOpen: PropTypes.func,
  handleSignOut: PropTypes.func,
};

TopBar.defaultProps = {
  logoVisible: true,
  getSelectedKey: () => {},
  setAuthDrawerOpen: () => {},
  handleSignOut: () => {},
};

export default memo(TopBar);
