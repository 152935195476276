import React, { useEffect } from 'react';
import { Drawer, Menu } from 'antd';
import { Link as NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Logo from './Logo';
import ProfileMenu from './ProfileMenu';
import { featureFlag } from '../../constant';
import { MarkIcon } from '../icons';
import { consoleMenuMobile } from '../sidebar/constant';
import NavUsersnap from './NavUsersnap';

const { SubMenu } = Menu;

const MobileMenu = (props) => {
  const {
    visible,
    selectedKey,
    onClose,
    handleSignOut,
    onClickSignIn,
  } = props;

  const history = useHistory();

  useEffect(() => {
    history.listen(() => {
      if (visible) {
        onClose();
      }
    });
  }, [history]);

  const menuItems = [
    {
      key: 'map',
      title: 'Map',
    },
    ...consoleMenuMobile,
  ];

  return (
    <Drawer
      className="mobile-nav"
      width="100%"
      onClose={onClose}
      visible={visible}
      closable
      placement="left"
      zIndex={1002}
      title={<Logo onNavigate={onClose} />}
    >
      <Menu
        defaultSelectedKeys={[selectedKey]}
        defaultOpenKeys={[consoleMenuMobile[0].key]}
        className="top-menu"
        onClick={onClose}
        mode="inline"
        style={{
          marginBottom: 40,
          paddingBottom: 40,
        }}
      >
        <Menu.Item
          key="seagull-mark"
          style={{
            height: 60,
            marginTop: 20,
            marginLeft: -5,
            marginBottom: 45,
          }}
        >
          <MarkIcon />
        </Menu.Item>
        {menuItems.map((menuItem) => (
          menuItem?.children
            ? (
              <SubMenu
                className="h2"
                key={menuItem?.key}
                title={`${menuItem?.title}`}
              >
                {menuItem?.children && menuItem?.children?.map((subMenuItem) => (
                  <Menu.Item key={subMenuItem?.key}>
                    <NavLink to={subMenuItem.key}>
                      <span>{subMenuItem?.title}</span>
                    </NavLink>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item className="h2" key={menuItem.key}>
                <NavLink to={`/${menuItem.key}`}>
                  <span>{menuItem.title}</span>
                </NavLink>
              </Menu.Item>
            )
        ))}
        <Menu.Item>
          <NavUsersnap />
        </Menu.Item>
      </Menu>
      {featureFlag.userProfile && (
        <ProfileMenu isMobile handleSignOut={handleSignOut} onClickSignIn={onClickSignIn} onMenuClick={onClose} />
      )}
    </Drawer>
  );
};

MobileMenu.propTypes = {
  selectedKey: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  handleSignOut: PropTypes.func,
  onClickSignIn: PropTypes.func,
};

MobileMenu.defaultProps = {
  selectedKey: '',
  visible: false,
  onClose: () => {},
  handleSignOut: () => {},
  onClickSignIn: () => {},
};

export default MobileMenu;
